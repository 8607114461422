import React from "react";
import styled from "styled-components";
import CareerLinkLogo from "../assets/images/CareerLink_Logo_Color_lg.svg";
import AmericanJobCenter from "../assets/images/AmericanJobCenter.svg";
import { respondTo } from "../styles/respond-to";

const StyledContainer = styled.div`
  background-color: var(--palette-very-light-gray);
  border-top: 10px solid var(--palette-light-blue);
  border-bottom: 1px solid var(--palette-light-blue);
  padding: 0 2rem;
  margin-bottom: 2rem;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);

  ${respondTo.sm`
    padding: 0 1rem;
  `}

  .container-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1rem;
    margin-top: 1rem;

    ${respondTo.sm`
      flex-direction: column;
      align-items: center;
    `}
  }

  .careerlink-logo {
    width: 150px;

    ${respondTo.sm`
      margin-bottom: 1rem;
    `}
  }

  .ajc-byline {
    font-size: 0.7rem;
    display: block;
  }

  .ajc-logo {
    display: inline-block;
    margin: 0 0.3rem;
  }
`;

const CareerLinkContainer = ({ children }) => {
  return (
    <StyledContainer>
      {children}

      <div className="container-footer">
        <a
          href="https://www.pacareerlink.pa.gov/jponline/individual"
          target="_new"
        >
          <img
            className="careerlink-logo"
            src={CareerLinkLogo}
            alt="PA CareerLink"
          />
        </a>

        <span className="ajc-byline">
          A proud partner of the
          <img
            className="ajc-logo"
            src={AmericanJobCenter}
            alt="American Job Center"
          />
          network
        </span>
      </div>
    </StyledContainer>
  );
};

export default CareerLinkContainer;
