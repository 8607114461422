import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import CareerLinkContainer from "./career-link-container";
import WelcomeVideo from "./welcome-video";
import { respondTo } from "../styles/respond-to";
import CareerlinkModal from "./careerlink-modal";

import { useGlobalContext } from "../context";

const StyledCareerLinkWork = styled.section`
  h2 {
    margin: 2rem 0 1rem;
  }

  .section-heading {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--palette-brown);
    font-family: RalewayBold;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 2rem;

    & + h2 {
      margin-top: 0.2rem;
    }
  }

  .list-with-columns {
    background-color: white;
    padding: 1rem;
    margin: 1rem auto;
    width: 90%;
    columns: 2;

    ${respondTo.sm`
      width: 100%;
    `}

    @media only screen and (max-width: 600px) {
      columns: 1;
    }

    li {
      padding: 0 1rem;
    }
  }
`;

const CareerLinkWork = () => {
  const { openModal } = useGlobalContext();

  const { person } = useStaticQuery(graphql`
    {
      person: file(relativePath: { eq: "careerlink-person.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData
        }
      }
    }
  `);

  return (
    <CareerLinkContainer>
      <StyledCareerLinkWork>
        <h2 id="careerlinkwork">Are you looking for work in PA Six?</h2>

        <p>
          PA CareerLink® centers provide one-stop delivery of career and
          workforce services to job seekers and employers, PA CareerLink®
          centers have an experienced team available to assist you in every
          aspect of your career search. PA CareerLink® centers also have an
          experienced business service team available to assist employers in
          recruiting and retaining a skilled workforce.
        </p>

        <h3>
          Services available to job seekers at each of our PA CareerLink®
          centers include:
        </h3>

        <ul className="list-with-columns">
          <li>
            Resource rooms with phones, free internet and resume writing tools
          </li>
          <li>Employment plan development</li>
          <li>Job training services</li>
          <li>Job search assistance</li>
          <li>Career counseling</li>
          <li>Practice interviewing</li>
          <li>Skills testing</li>
          <li>Labor market and employer information</li>
          <li>Employment workshops</li>
          <li>
            Supportive services (which can include information about SNAP,
            financial assistance, Medicaid, training services, child care,
            emergency funds, and other benefits)
          </li>
          <li>Hiring events and business service information</li>
          <li>
            Accessibility and special accommodations for people with
            disabilities
          </li>
          <li>Referrals to community resources and other agencies, and more</li>
        </ul>

        <div className="center-button-container">
          <button
            className="button-brown"
            onClick={(e) => {
              openModal(e);
            }}
          >
            Help in finding the perfect job is just a click away!
          </button>

          <p>
            <i>
              <a
                href="https://www.pacareerlink.pa.gov/jponline/individual"
                target="_new"
              >
                Visit PA CareerLink&reg;
              </a>{" "}
              for their on-line job matching to hundreds of jobs in PA Six.
            </i>
          </p>
        </div>

          <CareerlinkModal photo={person} />

        <h2>Our services are at no cost to you</h2>

        <p>
          Visit our 
          <a href="https://youtu.be/aOqCpRqvoqQ" target="_new">
            Welcome Video
          </a>{" "}
          to learn how we can help you find a job through our employment and
          training services. We have a variety of Services, at no cost to you,
          that are available to all job seekers in the PA Six, and we can also
          help you with your online job search!
        </p>
        <WelcomeVideo />
        <p>
          In addition, you may qualify for our Intensive Services. These
          services provide customized job search assistance, training services
          such as classroom and on-the-job training (OJT), and more. Through
          Intensive Services you can also meet with one of our Team Members, who
          will help you develop an Individualized Employment Plan and assist you
          with your job search.
        </p>

        <p>
          We hope you will take advantage of our employment resources online,
          but remember that a caring staff member is only a phone call away.
          Additionally, our Career Resource Centers are equipped with all the
          resources you need to begin to your job search!
        </p>
        <p>
          Our PA CareerLink® centers are funded primarily by the Pennsylvania
          Department of Labor and Industry and are certified by the North
          Central Workforce Development Board.
        </p>

        <h3 className="section-heading">Employer Services</h3>
        <h2>
          Find skilled workers at PA Six through{" "}
          <span className="pacareerlink">
            PA Careerlink<sup>&reg;</sup>
          </span>
        </h2>

        <p>
          At{" "}
          <span className="pacareerlink">
            PA CareerLink<sup>&reg;</sup>
          </span>
           centers our main business is to provide you with skilled workers. We
          promise that the employment, training, and labor market services you
          receive through our workforce delivery system are professional,
          comprehensive, and on-target.
        </p>
        <p>
          Best of all, all of our services are at no cost to you! View our
          employer services packet to learn more.
        </p>

        <h3>
          Services available to employers at each of our{" "}
          <span className="pacareerlink">
            PA CareerLink<sup>&reg;</sup>
          </span>
          centers include:
        </h3>
        <ul className="list-with-columns">
          <li>Search for Candidates</li>
          <li>On-the-Job Training</li>
          <li>Job Fairs</li>
          <li>Virtual Interviews</li>
          <li>Learn about Disability Service Partnerships</li>
          <li>Learn more about Apprenticeships</li>
          <li>Report a New Hire</li>
          <li>Job Market Data</li>
          <li>Apprenticeship Opportunities</li>
          <li>
            Unemployment Compensation Benefits and Unemployed Compensation Tax
          </li>
          <li>Workforce and Compliance-Related Resources</li>
          <li>Work Opportunity Tax Credit (WOTC) Program</li>
          <li>Federal Bonding</li>
          <li>Incumbent Worker Training</li>
          <li>Sector Partnerships</li>
        </ul>

        <div className="center-button-container">
          <a
            href="https://www.pacareerlink.pa.gov/jponline/Common/LandingPage/Employer"
            target="_new"
          >
            Visit PA CareerLink&reg;
          </a>{" "}
          for their on-line job matching tool.
        </div>
      </StyledCareerLinkWork>
    </CareerLinkContainer>
  );
};

export default CareerLinkWork;
