import React, { lazy, Suspense } from "react";
import CareerLinkWork from "../components/career-link-work";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HeroPage from "../components/hero-page";
import Seo from "../components/seo";

const Work = ({ data }) => {
  return (
    <>
      <Seo
        title="Finding work in North Central Pennsylvania"
        description="Finding work in the PA Six region of North Central Pennsylvania"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work in PA Six"
        caption="Miller Fabrication Solutions - Jefferson County"
        position="30%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>
              Finding work in the PA Six region of North Central Pennsylvania
            </h2>
            <p>
              The PA SIX region has a dynamic and diverse industry base with
              nearly 7,000 establishments.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Leading industries are Manufacturing, Healthcare and Social
              Assistance, Transportation and Logistics and Hospitality, Leisure
              and Entertainment. Key Manufacturing industries including Powdered
              Metal and Lumber and Wood.
            </p>
            <p>
              Top region employers include Zippo Manufacturing Company, Domtar
              Paper Company, Penn Highlands Healthcare and Wal-Mart Associates,
              Inc.
            </p>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-title">
            <h2>Industry in PA Six</h2>
            <p>
              From manufacturing to healthcare and social assistance to lumber
              and wood and education – there is a wide range of opportunities
              for all skill levels in PA Six.
            </p>

            <br />
            <figure>
              <GatsbyImage
                image={getImage(data.industryPhoto.sharp.image)}
                alt="Industry in PA Six"
              />
              <figcaption>
                Elk County Heat Treaters, Elk County (photo by: Bradd Celidonia,
                Third Shift Photography)
              </figcaption>
            </figure>
          </div>

          <div className="industry-links">
            <Link to="/work/advanced-manufacturing">
              Advanced Manufacturing
            </Link>
            <Link to="/work/agriculture-food-production">
              Agriculture and Food Production
            </Link>
            <Link to="/work/bio-medical">Bio Medical</Link>
            <Link to="/work/building-construction">
              Building and Construction
            </Link>
            <Link to="/work/business-services">Business Services</Link>
            <Link to="/work/education">Education</Link>
            <Link to="/work/energy">Energy</Link>
            <Link to="/work/health-care">
              Health Care and Social Assistance
            </Link>
            <Link to="/work/hospitality-leisure-entertainment">
              Hospitality, Leisure and Entertainment
            </Link>
            <Link to="/work/logistics-transportation">
              Logistics and Transportation
            </Link>
            <Link to="/work/real-estate-finance-insurance">
              Real Estate, Finance and Insurance
            </Link>
            <Link to="/work/wood-publishing">
              Wood, Wood Products and Publishing
            </Link>
          </div>
        </section>

        <CareerLinkWork />
      </main>
    </>
  );
};

export const query = graphql`
  query {
    industryPhoto: file(
      relativePath: {
        eq: "heat-treaters-250_1594605034_445_1594470035_DSC_9572.jpg"
      }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(relativePath: { eq: "CRP7120-miller-fabrication.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(
      relativePath: { eq: "CRP7120-miller-fabrication.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;
export default Work;
