import React from "react";
import styled from "styled-components";
import { respondTo } from "../styles/respond-to";

const StyledVideo = styled.iframe`
  float: right;
  margin: 0 0 1rem 1rem;
  width: 560px;
  height: 315px;

  ${respondTo.sm`
    margin: 0;
    float: none;
    width: 100%;
    height: 300px;
  `}
`;

const WelcomeVideo = () => {
  return (
    <StyledVideo
      src="https://www.youtube.com/embed/aOqCpRqvoqQ"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></StyledVideo>
  );
};

export default WelcomeVideo;
