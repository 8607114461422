import React from "react";
import styled from "styled-components";
import { FaTimes, FaPhoneAlt } from "react-icons/fa";
import { useGlobalContext } from "../context";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { respondTo } from "../styles/respond-to";

const ModalStyles = styled.div`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    transition: all 0.3s linear;
    visibility: hidden;
    z-index: -1;
  }

  .show-modal {
    visibility: visible;
    z-index: 2000;
  }

  .modal-container {
    background: radial-gradient(white, var(--palette-light-blue));

    border: 1px solid var(--palette-dark-brown);
    width: 80vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: scroll;
    padding: 2rem;
    margin: 1rem;

    ${respondTo.sm`
      padding: 1rem;
    `}

    .title-container {
      text-align: center;

      h2 {
        font-size: 3rem;
        font-family: RalewayBold;

        ${respondTo.sm`
          font-size: 2rem;
        `}
      }

      h3 {
        font-size: 2rem;
        color: var(--palette-brown);

        ${respondTo.sm`
          font-size: 1.2rem;
        `}
      }

      .phone {
        display: inline-block;
        margin: 0 2rem;
      }

      .tty {
        font-size: 1.5rem;
        color: var(--palette-blue);

        ${respondTo.md`
          font-size: 1rem;
          display: block;
        `}
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      ${respondTo.sm`
        flex-direction: column;
      `}

      ${respondTo.md`
        flex-direction: column;
      `}

      .text-container {
        width: 55%;
        text-align: left;
        font-size: 1.2rem;
        line-height: 2.2rem;

        ${respondTo.md`
          font-size: 1rem;
          width: 100%;
        `}

        ul {
        }
      }
      .photo-container {
        width: 40%;

        ${respondTo.md`
          width: 100%;
        `}
      }
    }
  }

  .close-modal-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: darkred;
    cursor: pointer;

    ${respondTo.sm`
      top: 0rem;
      right: 0rem;
      font-size: 1.5rem;
    `}
  }
`;

const CareerlinkModal = ({ photo }) => {
  const { isModalOpen, closeModal } = useGlobalContext();

  return (
    <ModalStyles>
      <div
        className={`${
          isModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <div className="title-container">
            <h2>Need a Job? Want Training?</h2>
            <h3>
              <span>
                Contact PA Careerlink<sup>&reg;</sup>
              </span>

              <span className="phone">
                <FaPhoneAlt /> 1-844-PACALINK
              </span>
              <span className="tty">TTY 814-274-0499</span>
            </h3>
          </div>

          <div className="content">
            <div className="text-container">
              <ul>
                <li>Adult education classes</li>
                <li>Employer services</li>
                <li>Career counseling</li>
                <li>Job search assistance</li>
                <li>On-the-job training programs</li>
                <li>Resume assistance</li>
                <li>Virtual workshops</li>
                <li>Veteran services</li>
                <li>Youth programming</li>
                <li>Work readiness services</li>
              </ul>

              <p>
                The{" "}
                <b>
                  PA CareerLink<sup>&reg;</sup>
                </b>{" "}
                system is an effective one-stop shop for Pennsylvania job
                seekers and employers. More information, including covid-19
                employment opportunities in the Commonwealth, is available at{" "}
                <a href="http://www.pacareerlink.pa.gov" target="_new">
                  www.pacareerlink.pa.gov
                </a>
              </p>

              <p>
                UC courtesy telephones remain unavailable in{" "}
                <b>
                  PA CareerLink<sup>&reg;</sup>
                </b>
                centers until further notice from the PA Department of Labor and
                Industry. For assistance, call <b>888-313-7284</b>
              </p>
            </div>
            <div className="photo-container">
              <GatsbyImage image={getImage(photo.sharp.image)} alt="PA Career Link" />
            </div>
          </div>

          <button
            className="close-modal-btn"
            onClick={(e) => {
              closeModal(e);
            }}
          >
            <FaTimes />
          </button>
        </div>
      </div>
    </ModalStyles>
  );
};

export default CareerlinkModal;
